import "./PopupContent.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { Loader } from "../../../components/loader/Loader";
import { useMemo } from "react";

interface IPopupContentProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

export function PopupContent(props: IPopupContentProps) {

  const popupContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("popup-content")
      .addConditional(props.className, props.className)
      .addConditional(props.isLoading, "hidden")
      .build();
  }, [props.className, props.isLoading]);


  return (
    <>
      {props.isLoading ? <Loader /> : null}
      <div className={popupContainerCss}>
        {props.children}
      </div>
    </>
  );
}
