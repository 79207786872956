import "./SystemPopup.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error-icon.svg";
import { PopupActionButtons } from "../../layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../separator/Spacer";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { useClosePopup } from "../../infrastructure/ui/UIServices";

interface IErrorPopupProps {
  children: React.ReactNode;
  className?: string;
  onDismissClicked?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function ErrorPopup(props: IErrorPopupProps) {
  const closePopup = useClosePopup();

  const popupCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("system-popup")
      .add("error-popup")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      props.onDismissClicked && props.onDismissClicked(ev);
      closePopup();
    },
    [props.onDismissClicked, closePopup]
  );

  return (
    <PopupContainer className={popupCss}>
      <div className="popup-icon">
        <ErrorIcon />
      </div>
      <Spacer px="14" mode="vertical" />
      <div className="popup-header">
        {translate("COMMON.SYSTEMPOPUPS.Error")}
      </div>

      <Spacer px="6" mode="vertical" />
      <div className="popup-content">{props.children}</div>
      <Spacer mode={"vertical"} px="30"></Spacer>
      <PopupActionButtons
        buttons={[
          {
            text: "Ok",
            type: "secondary",
            onClick: handleClicked,
          },
        ]}
      />
    </PopupContainer>
  );
}
