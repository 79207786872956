export class CancelHttpEvent {
   static cancelAllHttpRequests() {
      let event = new CustomEvent<void>("cancel-http");
      window.dispatchEvent(event);

   }

   static attachHandler(handler: EventListener) {
      window.addEventListener("cancel-http", handler);
   }

   static detachHandler(handler: EventListener) {
      window.removeEventListener("cancel-http", handler);
   }
}
