import "./CardContainer.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { Loader } from "../../../components/loader/Loader";
import { useMemo } from "react";

interface ICardContainerProps {
  children: React.ReactNode;
  className?: string;
}



export function CardContainer(props: ICardContainerProps) {
  const popupContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("card-container")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  return (

    <div className={popupContainerCss}>
      {props.children}
    </div>
  );
}



