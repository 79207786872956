import "./FullScreenLoader.css";
import { Overlay } from "../../layouts/containers/overlay/Overlay";
import { Loader } from "./Loader";

interface IFullScreenLoaderProps {
  text?: string;
}

export function FullScreenLoader(props: IFullScreenLoaderProps) {
  return (
    <Overlay>
      <div className="full-screen-loader-container">
        <Loader text={props.text} />
      </div>
    </Overlay>
  );
}
