import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { MenuItem } from "../../components/side-bar/MenuItem";

export interface UISContextState {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  popupContent: React.ReactNode;
  setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;

  popupStack: React.ReactNode[];
  setPopupStack: React.Dispatch<React.SetStateAction<React.ReactNode[]>>;

  popupContainerRef:React.RefObject<HTMLDivElement>;

  viewPortWidth: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  viewPortHeight: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;

  selectedProject: SelectedProject | undefined;
  setSelectedProject: React.Dispatch<
    React.SetStateAction<SelectedProject | undefined>
  >;

  sideBarButtons: MenuItem[];
  setSideBarButtons: React.Dispatch<React.SetStateAction<MenuItem[]>>;

  notificationContent: React.ReactNode;
  setNotificationContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  showNotificationContainer: boolean;
  setShowNotificationContainer: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SelectedProject {
  id: string;
  name: string;
}

export const UISContext = createContext<UISContextState | null>(null);

interface UISContextProps {
  children: React.ReactNode;
}

export function UISContextProvider({ children }: UISContextProps) {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<React.ReactNode>();
  const [popupStack, setPopupStack] = useState<React.ReactNode[]>([]);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [selectedProject, setSelectedProject] = useState<SelectedProject>();

  const [sideBarButtons, setSideBarButtons] = useState<MenuItem[]>([]);

  const [notificationContent, setNotificationContent] = useState<React.ReactNode>();
  const [showNotificationContainer, setShowNotificationContainer] = useState(false);


  const popupContainerRef = useRef<HTMLDivElement>(null);

  const value = {
    showPopup,
    setShowPopup,
    popupContent,
    setPopupContent,
    popupContainerRef,

    viewPortWidth: width,
    setWidth,
    viewPortHeight: height,
    setHeight,

    selectedProject,
    setSelectedProject,

    sideBarButtons,
    setSideBarButtons,

    notificationContent,
    setNotificationContent,
    showNotificationContainer,
    setShowNotificationContainer,
    popupStack,
    setPopupStack
  };

  return <UISContext.Provider value={value}>{children}</UISContext.Provider>;
}

/**************************************
 *  Selected Project
 *************************************/
export function useSelectedProject() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return context.selectedProject;
}

export function useSetSelectedProject(): [
  SelectedProject | undefined,
  React.Dispatch<React.SetStateAction<SelectedProject | undefined>>
] {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return [context.selectedProject, context.setSelectedProject];
}
/**************************************
 *  Popup
 *************************************/
export function useRenderPopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showPopup: context.showPopup,
    popupContent: context.popupContent,
  };
}

export function usePopupRef() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return context.popupContainerRef;
}

export function usePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const openPopup = useCallback(
    (content: React.ReactNode, clearStack = true) => {
      if(clearStack){
        context.setPopupStack([]);
      } else {
        context.setPopupStack([context.popupContent , ...context.popupStack])
      }
      context.setPopupContent(content);
      context.setShowPopup(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.setShowPopup, context.setPopupContent, context.popupContent, context.popupStack, context.setPopupStack]
  );

  return openPopup;
}

export function useClosePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closePopup = useCallback((options: {goToPreviousPopup: boolean} | null = null) => {

    if(context.popupStack.length) {

      context.setShowPopup(true);
      const stack = [...context.popupStack];
      context.setPopupContent(stack.shift());
      context.setPopupStack(stack);
    } else {
      context.setShowPopup(false);
      context.setPopupContent(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setShowPopup, context.setPopupContent, context.popupStack, context.setPopupStack]);

  return closePopup;
}

/**************************************
 *  Window Resize
 *************************************/
export function useWindowResize() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  useEffect(() => {
    const handleWindowResize = () => {
      context.setWidth(window.innerWidth);
      context.setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setWidth, context.setHeight]);

  return context.viewPortWidth;
}

export function useSideBarButtons() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return context.sideBarButtons;
}

/**************************************
 *  Sidebar Buttons
 *************************************/
export function useSetSideBarButtons() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const setSideBarButtons = useCallback(
    (content: MenuItem[]) => {
      context.setSideBarButtons(content);
    },
    [context]
  );

  return setSideBarButtons;
}


export function useNotification() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    notificationContent: context.notificationContent,
  };
}

export function useCreateNotification() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return useCallback((content: React.ReactNode) => {
      context.setNotificationContent(content);
      context.setShowNotificationContainer(true);
    },
    [context.setNotificationContent, context.setShowNotificationContainer]
  );
}

export function useRenderNotificationContainer() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showNotificationContainer: context.showNotificationContainer,
  };
}

export function useCloseNotificationContainer() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closeNotification = useCallback(() => {
    context.setShowNotificationContainer(false);
  }, [context.setShowNotificationContainer]);

  return closeNotification;
}