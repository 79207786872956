import { AuthError, EventMessage } from "@azure/msal-browser";

interface BaseIdentityClaims {
    exp: number;
    iat: number;
    iss: string;
    nbf: number;
    nonce: string;
    sub: string;
    tfp: string;
    ver: string;
    aud: string;
    auth_time: number;
    preferred_username:string;
}

export interface IdentityClaims extends BaseIdentityClaims {
    city: string;
    country: string;
    emails: string[],
    name: string;
    jobTitle: string;
    postalCode: string;
    state: string;
    streetAddress: string;
};




export class IdentityHelper {



    static getUserDisplayNameFromClaims(principal: IdentityClaims) {
        return principal && principal.name;
    }

    static getUserEmailFromClaims(principal: IdentityClaims) {

        if (principal
            && principal.emails
            && Array.isArray(principal.emails)
            && principal.emails.length > 0) {

            return principal.emails[0];
        }

        else if(principal
            && principal.preferred_username) {
                return principal.preferred_username;
            }

        else return null;
    }


    static errorMessageContains(message: EventMessage, text: string) {

        return (message
            && message.error
            && message.error instanceof AuthError
            && message.error.errorMessage
            && message.error.errorMessage.includes(text));

    }
}
