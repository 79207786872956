import "./SubSideBarButton.css";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./MenuItem";
import { useMemo } from "react";

const isActive = (props: { isActive: boolean; isPending: boolean }) =>
  props.isActive ? "selected" : "is-pending";

export function SubSideBarButton(props: MenuItem) {
  const isBottomAligned = useMemo(() => {
    return props.isBottomAligned ? "bottom-aligned" : "";
  }, [props.isBottomAligned]);

  return (
    <div className={`icon ${isBottomAligned}`}>
      <NavLink to={props.navigateTo} className={isActive}>
        {props.icon}
      </NavLink>
    </div>
  );
}


export function SubSideBarButton2(props: MenuItem) {
  const isBottomAligned = useMemo(() => {
    return props.isBottomAligned ? "bottom-aligned" : "";
  }, [props.isBottomAligned]);

  return (
    <div className="sub-side-bar-btn-2">
      <NavLink to={props.navigateTo} className={isActive}>
        <div className={`icon ${isBottomAligned}`}>
          {props.icon}
        </div>
        <div className="caption label">{props.name}</div>
      </NavLink>
    </div>
  );
}
