import "./PopupActionButtons.css";

import { Button, IButtonProps } from "../../../components/buttons/Button";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";

interface IPopupActionButtonsProps {
  className?: string;
  buttons: IButtonProps[];
}

export function PopupActionButtons(props: IPopupActionButtonsProps) {

  const popupBtnsCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("popup-buttons")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const renderBtns = useMemo(() => {
    if (props.buttons) {
      return props.buttons.map((btn, idx) => (
        <Button
          key={idx}
          text={btn.text}
          type={btn.type}
          icon={btn.icon}
          onClick={btn.onClick}
          isDisabled={btn.isDisabled}
          className={btn.className}
        ></Button>
      ));
    }
  }, [props.buttons]);

  return <div className={popupBtnsCss}>{renderBtns}</div>;
}
