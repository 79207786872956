import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { EditLocationGroupAccessPage } from "../features/admin/location-group-accesses/EditLocationGroupAccessPage";
import { FacilitiesHomePage } from "../features/admin/facilities/FacilitiesHomePage";
import { LocationEmployeeDetailsPage } from "../features/admin/employees/LocationEmployeeDetailsPage";
import { LocationEmployeesHomePage } from "../features/admin/employees/LocationEmployeesHomePage";
import { LocationsContainer } from "../features/admin/layout/LocationsContainer";
import { LocationsHome } from "../features/admin/locations/LocationsHomePage";
import { MaterialsHomePage } from "../features/admin/materials/MaterialsHomePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from '../models/api/gate/Permission';
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { TestTypesHomePage } from "../features/admin/testes/test-types/TestTypesHomePage";
import { TestsHomePage } from "../features/admin/testes/TestsHomePage";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";
import { LaboratoryEmailsPage } from "../features/admin/laboratory-emails/LaboratoryEmailsPage";

const moduleDefinition: ModuleDefinition = {
  basePath: "/settings/locations",
  routes: [
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.Locations"),
      pathPattern: "/",
    },
    {
      name: () => "##LOCATION##",
      pathPattern: "/:id",
    },
    {
      name: () => translate("GLOBALADMIN.MATERIALS.Materials"),
      pathPattern: "/:id/materials",
    },
    {
      name: () => translate("GLOBALADMIN.TESTS.Tests"),
      pathPattern: "/:id/tests",
    },
    {
      name: () => translate("GLOBALADMIN.TESTTYPES.TestTypes"),
      pathPattern: "/:id/tests/types",
    },
    {
      name: () => translate("GLOBALADMIN.FACILITIES.Facilities"),
      pathPattern: "/:id/facilities",
    },
    {
      name: () => translate("GLOBALADMIN.ACCESSGROUPS.AccessGroups"),
      pathPattern: "/:id/groupaccess",
    },
    {
      name: () => translate("GLOBALADMIN.EMPLOYEES.LabOperators"),
      pathPattern: "/:id/employees",
    },
    {
      name: () => "##EMPLOYEE##",
      pathPattern: "/:id/employees/:employeeId",
    },
    {
      name: () => translate("GLOBALADMIN.EMAILS.Emails"),
      pathPattern: "/:id/emails",
    },

  ],
};




const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];

export function LocationsModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();


  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  return (

    <RouteGuard permissions={permissions} >
      <Routes>
        <Route index element={<LocationsHome />} />
        <Route
          path=":id/*"
          element={
            <LocationsContainer>
              <Outlet />
            </LocationsContainer>}
        >
          <Route index element={<Navigate to="materials" />} />
          <Route path="tests" element={<TestsHomePage />} />
          <Route path="tests/types" element={<TestTypesHomePage />} />
          <Route path="materials" element={<MaterialsHomePage />} />
          <Route path="groupaccess" element={<EditLocationGroupAccessPage />} />
          <Route path="facilities" element={<FacilitiesHomePage />} />
          <Route path="employees" element={<LocationEmployeesHomePage />} />
          <Route path="emails" element={<LaboratoryEmailsPage />} />
          <Route path="employees/:employeeId" element={<LocationEmployeeDetailsPage />} />
        </Route>

      </Routes>
    </RouteGuard>
  );
}
