import { Optional, ValidatorFunction } from "../components/form/Form";

import { DateTime } from "luxon";
import { translate } from "../infrastructure/i18n/InternationalizationService";

export function hasOneElementSelected<A>(): ValidatorFunction<Optional<A>> {
    return (value: A | undefined) => {
        if (!value) {
            return translate("COMMON.FORM.VALIDATIONS.RequiredField");
        }
        return null;
    };
}


