import { LaboratoryEmailsSummaryDTO } from "../features/admin/laboratory-emails/models/LaboratoryEmailsSummaryDTO";
import { LaboratoryEmailsUpdateDTO } from "../features/admin/laboratory-emails/models/LaboratoryEmailsUpdateDTO";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";


const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class LaboratoryEmailsService {

    getLocationLaboratoryEmails(locationId: string): Promise<LaboratoryEmailsSummaryDTO> {
        return HttpClient.sessionRequest<LaboratoryEmailsSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LaboratoryEmails`),
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            throw error
        });
    }


    editLocationLaboratoryEmails(locationId: string, request: LaboratoryEmailsUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest<LaboratoryEmailsUpdateDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LaboratoryEmails`),
            data: request
        }).then((_) => {

        }).catch((error) => {
            throw error
        });
    }
}