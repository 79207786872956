import { InternationalizationProvider } from "./InternationalizationProvider";

export class InternationalizationService {
  static setLocaleEN() {
    InternationalizationProvider.setCurrentLocale("en-EN");
  }

  static setLocalePT() {
    InternationalizationProvider.setCurrentLocale("pt-PT");
  }

  static getLocale() {

    return InternationalizationProvider.getCurrentLocale();
  }
}

export function translate(key: string, ...format: string[]): string {
  return InternationalizationProvider.translate(InternationalizationProvider.getCurrentLocale(), key, ...format);
}
