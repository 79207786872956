import "./MobileNavBar.css";

import { useCallback, useMemo, useState } from "react";

import { AccountInfo } from "./NavBar";
import { AccountMenu } from "../account-menu/AccountMenu";
import { ReactComponent as ClosePanel } from "../../assets/icons/close-icon.svg";
import { ContextualMenuItems } from "../contextual-menu/ContextualMenu";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { ReactComponent as LogoDark } from "../../assets/logo/valmet-logo-dark.svg";
import { ReactComponent as LogoLight } from "../../assets/logo/valmet-logo-white.svg";
import { MenuItem } from "../side-bar/MenuItem";
import { ReactComponent as MobileMenu } from "../../assets/icons/mobile-menu.svg";
import { MobileNavButton } from "./MobileNavButton";
import { ScalableIconButton } from "../buttons/ScalableIconButton";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { useNavigate } from "react-router-dom";

interface IMobileNavBarProps {
  buttons?: MenuItem[];
  accountInfo?: AccountInfo;
  languages?: ContextualMenuItems[];
}

export function MobileNavBar(props: IMobileNavBarProps) {
  const navigate = useNavigate();

  const [isMobileMenuPanelOpen, setIsMobileMenuPanelOpen] =
    useState<boolean>(false);

  const handleMenuClick = useCallback(() => {
    setIsMobileMenuPanelOpen(!isMobileMenuPanelOpen);
  }, [setIsMobileMenuPanelOpen, isMobileMenuPanelOpen]);

  const renderButtons = useMemo(() => {
    if (props.buttons) {
      return (
        <ul>
          {props.buttons.map((btn, i) => (
            <li
              key={i}
              onClick={() => {
                setIsMobileMenuPanelOpen(false);
              }}
            >
              <MobileNavButton
                icon={btn.icon}
                to={btn.navigateTo}
                name={btn.name}
              />
            </li>
          ))}
        </ul>
      );
    }
  }, [props.buttons, setIsMobileMenuPanelOpen]);

  const renderAccountInfo = useMemo(() => {
    if (props.accountInfo) {
      return (
        <div className="account">
          <AccountMenu
            name={props.accountInfo.info.name}
            email={props.accountInfo.info.email}
          />
          <hr />
          <div className="account-buttons">
            <ul>
              {props.accountInfo.menuItems &&
                props.accountInfo.menuItems.map((btn, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setIsMobileMenuPanelOpen(false);
                    }}
                  >
                    <MobileNavButton
                      icon={btn.icon}
                      to={btn.navigateTo}
                      name={btn.label}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      );
    }
  }, [props.accountInfo, setIsMobileMenuPanelOpen]);

  const renderPanel = useMemo(() => {
    if (isMobileMenuPanelOpen) {
      return (
        <div className="mobile-nav-panel">
          <div className="menu-content">
            {renderButtons}
            {renderAccountInfo}
          </div>
        </div>
      );
    }
  }, [isMobileMenuPanelOpen, renderAccountInfo, renderButtons]);

  const renderLogo = useMemo(() => {
    if (isMobileMenuPanelOpen) return <LogoDark />;
    else return <LogoLight />;
  }, [isMobileMenuPanelOpen]);

  const renderNavIcon = useMemo(() => {
    if (isMobileMenuPanelOpen)
      return <ClosePanel className="mobilenav-close-icon" />;
    else return <MobileMenu />;
  }, [isMobileMenuPanelOpen]);

  const mobileNavbarCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("mobile-navbar")
      .addConditional(isMobileMenuPanelOpen, "light-bg")
      .build();
  }, [isMobileMenuPanelOpen]);

  return (
    <div className="mobile-nav">
      <div className={mobileNavbarCss}>
        <div className="mobile-navbar-logo-project">
          <div className="mobile-navbar-logo" onClick={() => navigate("/")}>
            {renderLogo}
          </div>
          <div className="project">{translate("COMMON.ProjectName")}</div>
        </div>
        <div className="mobile-navbar-options">
          <ScalableIconButton
            icon={renderNavIcon}
            size={36}
            onClick={() => handleMenuClick()}
          />
        </div>
      </div>
      {renderPanel}
    </div>
  );
}
