import "./RoundIconButton.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface IButtonProps {
    icon: React.ReactNode;
    isDisabled?: boolean;
    className?: string;
    onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function RoundIconButton(props: IButtonProps) {
    const buttonCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("round-icon-button")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.isDisabled]);


    const handleClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (props.isDisabled) return;
            props.onClick && props.onClick(ev);
        },
        [props.onClick, props.isDisabled]
    );

    
    return (
        <div className={buttonCss} onClick={handleClicked}>
            {props.icon}
        </div>
    );
}
