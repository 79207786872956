import "./PopupArea.css";

import { useCallback, useMemo } from "react";
import { useClosePopup, usePopupRef } from "../../../infrastructure/ui/UIServices";

import { CssClassnameBuilder } from '../../../utils/CssClassnameBuilder';
import { Overlay } from "../overlay/Overlay";

interface IPopupProps {
  children: React.ReactNode;
  className?: string;
}

export function PopupArea(props: IPopupProps) {
  const closePopup = useClosePopup();
  const ref = usePopupRef();

  const popupAreaCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("popup-area")
      .addConditional(props.className, props.className)
      .build()
  }, [props.className]);

  const handleOnMouseDown = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
  }, []);

  const handleOverlayClick = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // closePopup();
  }, []);

  return (
    <Overlay className="popup-overlay" onMouseDown={handleOverlayClick} >
      <div className={popupAreaCss} ref={ref}>
        <div onMouseDown={handleOnMouseDown}>
          {props.children}
        </div>

      </div>
    </Overlay>
  );
}
