import "./SplashScreen.css";
import { ReactComponent as Logo } from "../../../lib/assets/logo/valmet-logo-dark.svg";
import { Loader } from "../../../lib/components/loader/Loader";

export function SplashScreen() {
  return (
    <div className="splash-screen">
      <div className="splash-logo">
        <Logo />
        <Loader />
      </div>
    </div>
  );
}
