import "./LaboratoryEmailsPage.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { Button } from "../../../lib/components/buttons/Button";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../services/UseServiceCall";
import { LaboratoryEmailsService } from "../../../services/LaboratoryEmailsService";
import { EditLaboratoryEmailPopup } from "./popups/EditLaboratoryEmailPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";


var svc = new LaboratoryEmailsService();

export function LaboratoryEmailsPage() {

    const { id: locationId } = useParams();
    const openPopup = usePopup();

    const getLaboratoryEmailsCall = useServiceCallPro(svc.getLocationLaboratoryEmails);
    const [emails, setEmails] = useState<string[]>([]);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getLaboratoryEmails = useCallback(() => {
        if (!locationId) {
            return;
        }
        getLaboratoryEmailsCall.invoke(locationId)
            .then((response) => {

                if (!response.emails) {
                    setEmails([]);
                    return;
                }

                setEmails(response.emails
                    .split(';')
                    .map(email => email.trim()));
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>, false);
            })
    }, [getLaboratoryEmailsCall.invoke,
        locationId,
        setEmails,
        openPopup]);




    /****************************
    * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getLaboratoryEmails();
    }, [locationId]);



    /****************************
    * USER ACTIONS 
    *****************************/

    const handleOnClickActionBtn = useCallback(() => {

        if (!locationId) return;

        openPopup(<EditLaboratoryEmailPopup locationId={"" + locationId} onCompletedOperations={getLaboratoryEmails} />);

    }, [openPopup, getLaboratoryEmails, locationId]);




    /****************************
     * CSS & HTML
     *****************************/

    const materialColumnsList: ColumnDefinition<string>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v,
            width: "10%",
            headerRender: "Email",
        },
    ], []);



    return (
        <PageLayout
            tabTitle={translate("GLOBALADMIN.EMAILS.Emails")}
            pageTitle={translate("GLOBALADMIN.EMAILS.DistributionLaboratoryEmails")}
            className="laboratory-emails-home"
            showBreadcrumb
            actions={<Button
                text={translate("COMMON.Edit")}
                type="primary"
                onClick={handleOnClickActionBtn}
            />}
        >
            <>
                <div className="small-copy explanation">{translate("GLOBALADMIN.EMAILS.DistributionLaboratoryEmailsPageExplanation")}</div>
                <Spacer mode="vertical" px="20" />
                <ResponsiveDataTable
                    columnDefinitions={materialColumnsList}
                    items={emails || []}
                    totalitems={emails.length || 0}
                    isLoading={getLaboratoryEmailsCall.isLoading}
                ></ResponsiveDataTable>
            </>
        </PageLayout>
    );
}
