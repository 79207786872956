import { createContext, useState } from "react";

export interface IPermission {
  id: string;
}

export interface IdentitySvcsContextState {
  permissions: IPermission[];
  setPermissions: (value: IPermission[]) => void;
}

export const IdentitySvcContext = createContext<IdentitySvcsContextState | null>(null);

interface IIdentitySvcsContextProps {
  children: React.ReactNode;
}

export function IdentityServices({ children }: IIdentitySvcsContextProps) {
  const [permissions, setPermissions] = useState<IPermission[]>([]);

  const value = {
    permissions,
    setPermissions,
  };

  return <IdentitySvcContext.Provider value={value}>{children}</IdentitySvcContext.Provider>;
}
