import "./SubSidebar.css";

import { MenuItem } from "./MenuItem";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { SubSideBarButton, SubSideBarButton2 } from "./SubSideBarButton";
import { useMemo } from "react";

export interface ISideBarProps {
  className?: string;
  buttons: MenuItem[];
}

export function SubSidebar(props: ISideBarProps) {
  const sidebarCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("sub-side-bar")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const sideBarButtons = useMemo(() => {
    return props.buttons.map((m, idx) => {
      return (
        <SubSideBarButton2
          key={idx}
          icon={m.icon}
          navigateTo={m.navigateTo}
          name={m.name}
        />
      );
    });
  }, [props.buttons]);

  return <div className={sidebarCss}>{sideBarButtons}</div>;
}
