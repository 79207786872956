export class CssHelper {


    static pxToRem(px: any, base = 14) {
        let tempPx = px
        if (typeof px === 'string' || px instanceof String)
            tempPx = tempPx.replace('px', '')

        tempPx = parseInt(tempPx)
        return (1 / base) * tempPx + 'rem'
    }
}
