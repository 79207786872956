import "./Overlay.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";

interface IOverlayProps {
  children: React.ReactNode;
  className?: string;
  onMouseDown?: (ev: React.MouseEvent<HTMLDivElement>) => void;
}

export function Overlay(props: IOverlayProps) {

    const overlayCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("overlay")
            .addConditional(props.className, props.className)
            .build()
    }, [props.className]);


  return (
    <div className={overlayCss} onMouseDown={props.onMouseDown}>
        {props.children}
    </div>
  );
}
