import "./Checkbox.css";

import { useCallback, useMemo, useState } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface ICheckboxProps {
  text: string;
  additionalText?: string | null;
  additionalTextColor?: "red" | "yellow" | "green";
  isDisabled?: boolean;
  isChecked: boolean;
  className?: string;
  onClick?: (isChecked: boolean) => void;
}

export function Checkbox(props: ICheckboxProps) {
  /* 
    const [isChecked, setIsChecked] = useState(props.isChecked); */

  const checkboxCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("checkbox-container")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);


  const checkboxInputCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("checkbox")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.isChecked, "checked")

      .build();
  }, [props.isDisabled, props.isChecked, props.additionalText, props.additionalTextColor]);


  const checkboxLabelCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("label")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className, props.isDisabled]);



  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      /*       setIsChecked(!props.isChecked); */
      props.onClick && props.onClick(!props.isChecked);
    },
    [props.onClick, props.isDisabled, /* setIsChecked */, props.isChecked]
  );


  const checkboxAdditionalTextLabelCss = useMemo(() => {
    return CssClassnameBuilder.new()

      .addConditional(props.additionalText, "additional-text")
      .addConditional(props.additionalTextColor, props.additionalTextColor)
      .build();
  }, [props.additionalText, props.additionalTextColor]);


  return (
    <div className={checkboxCss}>
      <div className={checkboxInputCss} onClick={handleClicked} />
      <div className={checkboxLabelCss}>{props.text}
        <div className={checkboxAdditionalTextLabelCss}>{props.additionalText}</div>
      </div>
    </div>

  );
}
