import React, { createContext, useContext, useMemo, useState } from "react";

export interface LocationSvcsContextState {
  location: number | undefined;
  setLocation: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const LocationSvcContext =
  createContext<LocationSvcsContextState | null>(null);

interface ILocationSvcsContextProps {
  children: React.ReactNode;
}

export function LocationServices({ children }: ILocationSvcsContextProps) {

  const [location, setLocation] = useState<number>();

  const value = {
    location,
    setLocation
  };

  return (
    <LocationSvcContext.Provider value={value}>
      {children}
    </LocationSvcContext.Provider>
  );
}


export function useGlobalLocation() {
  const context = useContext(LocationSvcContext);
  if (!context) {
    throw new Error("useGlobalLocation must be used within LocationServices");
  }

  return context.location;
}



export function useSetGlobalLocation() {
  const context = useContext(LocationSvcContext);
  if (!context) {
    throw new Error("useSetGlobalLocation must be used within LocationServices");
  }

  return context.setLocation;
}
