import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Startup } from './lib/infrastructure/app/Startup';
import { BrowserRouter } from 'react-router-dom';
import { SPAApp } from './lib/infrastructure/app/SPAApp';
import { AppRoutingModule } from './routing-models/App.RoutingModule';

Startup.Run(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(

    <BrowserRouter>
      <SPAApp>
     
        <App />
    
      </SPAApp>
    </BrowserRouter>

  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});




