import "./AccountMenu.css";

import { Avatar } from "../avatar/Avatar";
import { useWindowResize } from "../../infrastructure/ui/UIServices";
export interface IAccountMenuProps {
  name: string;
  email: string;
  personaStatus?: string;
}

export function AccountMenu(props: IAccountMenuProps) {
  const windowResize = useWindowResize();

  return (
    <div className="account-menu-container">
      <div className="account-info">
        <div className="account-info-name">{props.name}</div>
        {windowResize >= 1024 ?
          <div className="account-info-email small-copy">{props.email}</div> : null}
      </div>
      <div>
        <Avatar name={props.name} />
      </div>
    </div>
  );
}
