import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { GetUserSettingsResponseDTO } from "../models/api/users/GetUserSettingsResponseDTO";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { LocationsResponseDTO } from "../models/api/location/LocationsResponseDTO";
import { UserLocation } from "../models/api/users/UserLocation";
import { UserLocationsResponseDTO } from "../models/api/users/UserLocationsResponseDTO";
import { UserSettings } from "../models/domain/UserSettings";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class UserSettingsService {



    getSelectedLanguage(): Promise<UserSettings> {
        return HttpClient.sessionRequest<GetUserSettingsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Me/Language`)
        }).then((response) => {

            //TEMP FIX.
            var userSettings: UserSettings = {
                id: response?.data?.id,
                value: response?.data?.value
            };

            return userSettings;
        }).catch((error) => {

            throw error;
        });
    }


    selectLanguage(languageId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Me/Language/${languageId}`),

        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }



    getUserLocations(): Promise<UserLocation[] | null> {
        return HttpClient.sessionRequest<UserLocationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Me/Locations`),
        })
            .then((response) => {
  
                if(!response.data.userLocations) return null;

                var mappedLocations = response?.data?.userLocations.map(
                    (l): UserLocation => ({
                        locationId: l?.locationId,
                        locationName: l?.name
                    })
                );
                return mappedLocations;
            })
            .catch((error) => {

                throw error;
            });
    }
}
