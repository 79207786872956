import axios, { AxiosHeaders, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from "axios";

import { ConfigurationProvider } from '../configuration/ConfigurationProvider';
import { IdentityService } from "../identity/IdentityService";
import { InternationalizationService } from "../i18n/InternationalizationService";
import { v4 as uuidv4 } from 'uuid';

function getSessionId() : string | null {
  const sessionStoreName = `${ConfigurationProvider.getConfiguration().App.Name}-SessionId`;

  if(!sessionStorage.getItem(sessionStoreName)) {
    sessionStorage.setItem(sessionStoreName, uuidv4())
  }

  return sessionStorage.getItem(sessionStoreName);
}

function initHeaders(config: AxiosRequestConfig, headerFunc?: (headers: (RawAxiosRequestHeaders) | AxiosHeaders) => void){
  if (!config.headers) {
    config.headers = {
    };
  }

  config.headers["Aurora-SessionId"] = getSessionId();

  config.headers["i18n-lang"] = InternationalizationService.getLocale();

  if(headerFunc) headerFunc(config.headers);
}


export class HttpClient {
  static sessionRequest<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {

    return IdentityService.Global.getAccessToken().then((accessToken) => {
      let requestOptions = { ...config };

      initHeaders(requestOptions, headers => {
        if (accessToken) {
           headers["Authorization"] = `Bearer ${accessToken}`;
        }
      });

      return axios.request<T>({ ...requestOptions });
    });
  }

  static request<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    let requestOptions = { ...config };

    initHeaders(requestOptions);

    return axios.request<T>({ ...requestOptions });
  }
}
