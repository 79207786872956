import "./TableFooter.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as ArrowSVG } from "../../../assets/icons/arrowRight.svg";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { PaginationOptions } from "../TableInterfaces";
import { TableSelectSingle } from "../table-single-select/TableSelectSingle";
import { isNull } from "../../../validators/ValidationChecks";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

export interface ITableFooterProps {
  className?: string;
  columnCount: number;
  currentPage?: number;
  totalItems: number;
  paginationOptions?: PaginationOptions;
  //onCurrentPageChanged?: (rowsPerPage: number, newPage: number) => void;
  onPageAndItemsChanged?: (rowsPerPage: number, newPage: number) => void;
}

export function TableFooter(props: ITableFooterProps) {
  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  const itemsPerPage = useMemo(
    () => props.paginationOptions?.itemsPerPage,
    [props.paginationOptions?.itemsPerPage]
  );

  const totalPages = useMemo(() => {
    if (
      props.totalItems > 0 &&
      itemsPerPage &&
      props.currentPage !== undefined
    ) {
      return Math.ceil(props.totalItems / itemsPerPage);
    }

    return 1;
  }, [props.totalItems, itemsPerPage, props.currentPage]);

  const hasNextPage = useMemo(() => {
    return props.currentPage === undefined
      ? false
      : props.currentPage < totalPages - 1;
  }, [props.currentPage, totalPages]);

  const hasPreviousPage = useMemo(() => {
    return props.currentPage === undefined ? false : props.currentPage > 0;
  }, [props.currentPage]);

  // useEffect(() => {
  //   if (props.totalItems > 0 && itemsPerPage && props.currentPage !== undefined) {
  //     const newTotalPages = Math.ceil(props.totalItems / itemsPerPage);
  //     const currentPage = props.currentPage > newTotalPages ? newTotalPages - 1 : props.currentPage;
  //     //setTotalPages(newTotalPages);
  //     //props.onPageAndItemsChanged && props.onPageAndItemsChanged(itemsPerPage, currentPage);
  //   }
  // }, [props.totalItems, itemsPerPage]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleNextPageClicked = useCallback(() => {
    if (props.currentPage !== undefined && hasNextPage) {
      props.onPageAndItemsChanged &&
        itemsPerPage &&
        props.onPageAndItemsChanged(itemsPerPage, props.currentPage + 1);
    }
  }, [
    props.currentPage,
    props.onPageAndItemsChanged,
    hasNextPage,
    itemsPerPage,
  ]);

  const handlePreviousPageClicked = useCallback(() => {
    if (props.currentPage !== undefined && hasPreviousPage) {
      props.onPageAndItemsChanged &&
        itemsPerPage &&
        props.onPageAndItemsChanged(itemsPerPage, props.currentPage - 1);
    }
  }, [
    props.currentPage,
    props.onPageAndItemsChanged,
    hasPreviousPage,
    itemsPerPage,
  ]);

  const handleItemsAndCurrentPageChanged = useCallback(
    (items: number) => {
      if (props.totalItems > 0 && items && props.currentPage !== undefined) {
        const newTotalPages = Math.ceil(props.totalItems / items);
        const currentPage =
          props.currentPage >= newTotalPages
            ? newTotalPages - 1
            : props.currentPage;
        /*  console.log("Table::handleItemsAndCurrentPageChanged", {
          propsTotalItems: props.totalItems, items, propsCurrentPage: props.currentPage,
          newTotalPages: newTotalPages, newCurrentPage: currentPage
        }); */
        //setTotalPages(newTotalPages);
        props.onPageAndItemsChanged &&
          props.onPageAndItemsChanged(items, currentPage);
      }
    },
    [props.onPageAndItemsChanged, props.totalItems, props.currentPage]
  );

  /****************************
   * CSS && HTML
   *****************************/

  const tableFooterCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("small-copy")
      .add("table-footer-pagination")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const nextButtonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("table-footer-pagination-button")
      .addConditional(!hasNextPage, "disable")
      .build();
  }, [hasNextPage]);

  const previousButtonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("table-footer-pagination-button")
      .add("rotate")
      .addConditional(!hasPreviousPage, "disable")
      .build();
  }, [hasPreviousPage]);

  const itemRange = useMemo(() => {
    if (itemsPerPage && props.currentPage !== undefined) {
      let first = 0;
      //const totalPages = Math.ceil(props.totalItems / itemsPerPage);
      const currentPage =
        props.currentPage > totalPages ? totalPages : props.currentPage;

      if (totalPages === currentPage) {
        first = itemsPerPage * (currentPage - 1);
      } else {
        first = (currentPage - 1) * itemsPerPage + itemsPerPage;
      }

      let second = Math.min(first + itemsPerPage, props.totalItems);
      return first + 1 + " - " + second + ` ${translate("COMMON.TABLE.Of")} `;
    }
    return undefined;
  }, [props.totalItems, props.currentPage, itemsPerPage, totalPages]);

  return (
    <tr>
      {props.paginationOptions ? (
        <td colSpan={props.columnCount}>
          <div className={tableFooterCss}>
            {props.paginationOptions.itemsPerPageOptions.length > 1 ?
              <TableSelectSingle
                onChange={handleItemsAndCurrentPageChanged}
                options={props.paginationOptions.itemsPerPageOptions}
                value={props.paginationOptions.itemsPerPage}
              /> : null}

            <div className="small-copy table-footer-pagination-info">
              {itemRange}
              {props.paginationOptions ? props.totalItems : null}
            </div>

            {!isNull(props.currentPage) ? (
              <div className="table-footer-pagination-buttons">
                <div
                  className={previousButtonCss}
                  onClick={handlePreviousPageClicked}
                >
                  <ArrowSVG />
                </div>
                <div className={nextButtonCss} onClick={handleNextPageClicked}>
                  <ArrowSVG />
                </div>
              </div>
            ) : null}
          </div>
        </td>
      ) : null}
    </tr>
  );
}
