import { Navigate, Route, Routes } from "react-router-dom";

import { IdentityAction } from "../lib/infrastructure/identity/components/IdentityAction";
import { SessionExpiredPage } from "../common/pages/session-expired/SessionExpiredPage";
import { UnavailableService } from "../common/pages/error/UnavailableService";

export function AppIdentityRoutingModule() {

    return (
        <Routes>
            <Route path="login" element={<IdentityAction exec={(svc) => svc.loginBackToUrl("/")} />} />
            <Route path="logout" element={<IdentityAction exec={(svc) => svc.logout()} />} />
            <Route path="sessionexpired" element={<SessionExpiredPage />} />
            <Route path="unavailable" element={<UnavailableService/>}/>

            {/*              <Route path="forgottenPassword" element={<IdentityAction exec={(svc) => svc.forgottenPassword()} />} /> * */}

            <Route path="*" element={<Navigate to="/notfound" />} />
        </Routes>
    )
}