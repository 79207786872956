import "./Space.css";

import { CssHelper } from "../../utils/CssHelper";
import { useMemo } from "react";

export interface ISpacerProps {
  mode: "horizontal" | "vertical";
  px?: string | number;
}

export function Spacer(props: ISpacerProps) {
  const style = useMemo(() => {
    let px = props.px || "20";

    return props.mode === "horizontal"
      ? { minWidth: CssHelper.pxToRem(px) }
      : { minHeight: CssHelper.pxToRem(px) };
  }, [props.mode, props.px]);

  return <div className="spacer" style={style}></div>;
}
