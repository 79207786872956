import "./ScalableIconButton.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface IScalableIconButtonProps {
  size: number;
  icon: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function ScalableIconButton(props: IScalableIconButtonProps) {
  const sizeCss = useMemo(() => {
    const baseFontSize = parseFloat(
      window.getComputedStyle(document.documentElement).fontSize
    );
    return {
      width: props.size / baseFontSize + "rem",
      height: props.size / baseFontSize + "rem",
    };
  }, [props.size]);

  const buttonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("menu-table-button")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className, props.isDisabled]);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      props.onClick && props.onClick(ev);
    },
    [props.onClick, props.isDisabled]
  );

  return (
    <div
      className={buttonCss}
      style={sizeCss}
      onClick={props.onClick ? handleClicked : () => {}}
    >
      {props.icon}
    </div>
  );
}
