import { Optional, ValidatorFunction } from "../components/form/Form";

import { DateTime } from "luxon";
import { translate } from "../infrastructure/i18n/InternationalizationService";

export function validateDateTime(): ValidatorFunction<Optional<DateTime>> {
    return (value: DateTime | undefined) => {
      if (!value) {
        return translate("COMMON.FORM.VALIDATIONS.SelectDate");
      }
      return null;
    };
  }
  