import "./MobileNavButton.css";
import React from "react";
import { NavLink } from "react-router-dom";

export interface IMobileNavButtonProps {
  icon: React.ReactNode;
  to: string;
  name: string;
}

export function MobileNavButton(props: IMobileNavButtonProps) {
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        isActive ? "mobile-nav-btn selected" : "mobile-nav-btn"
      }
    >
      <div className="mobile-nav-btn-container">
        <div className="mobile-nav-btn-icon">{props.icon}</div>
        <div className="mobile-nav-btn-name">{props.name}</div>
      </div>
    </NavLink>
  );
}
