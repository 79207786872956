import "./Breadcrumb.css";

import { DotsLoader } from "../loader/DotsLoader";
import React from "react";

export interface IBreadcrumbItem {
  text?: string;
  key?: string;
  onClick?: () => void;
}

export interface IBreadcrumbProps {
  items: IBreadcrumbItem[];
}

//TODO: Tiago revê

export function Breadcrumb(props: IBreadcrumbProps) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {props.items.map((item, index) => (
          <React.Fragment key={index}>
            <li className="breadcrumb-item">
              {item?.text?.includes("##") ? (
                <DotsLoader />
              ) : (
                <div onClick={item.onClick}>{item.text}</div>
              )}
            </li>
            <li className="breadcrumb-separator"> / </li>
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}
