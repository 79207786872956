export class LangChangedEvent {
    static fireLanguageChanged() {
       let event = new CustomEvent<void>("language-changed");
       window.dispatchEvent(event);
    }
 
    static attachHandler(handler: EventListener) {
       window.addEventListener("language-changed", handler);
    }
 
    static detachHandler(handler: EventListener) {
       window.removeEventListener("language-changed", handler);
    }
 }