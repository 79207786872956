import "./Avatar.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface IAvatarProps {
  name: string;
  state?: "primary" | "secondary" | "tertiary" | "quaternary";
  // icon?: React.ReactNode;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function Avatar(props: IAvatarProps) {
  const avatarCss = useMemo(() => {
    return (
      CssClassnameBuilder.new()
        .add("avatar")
        // .addConditional(props.icon, "has-icon")
        .addConditional(props.className, props.className)
        .build()
    );
  }, [props.className]);

  const avatarStateCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("avatar-state")
      .addConditional(props.state, props.state)
      .build();
  }, [props.state]);

  const avatarText = useMemo(() => {
    const words = props.name.split(" ");
    const firstLetters = words.map((word) => word.charAt(0));

    return firstLetters.join("");
  }, [props.name]);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (props.onClick) {
        ev.stopPropagation();
        props.onClick(ev);
      }
    },
    [props.onClick]
  );

  return (
    <div className="avatar-container" onClick={handleClicked}>
      <div className={avatarCss}>
        {/* {props.icon ? <div className="button-icon">{props.icon}</div> : null} */}
        {avatarText}
      </div>
      <div className={avatarStateCss} />
    </div>
  );
}
