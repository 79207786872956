import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconWarning } from "../../../lib/assets/icons/warning-page-icon.svg";
import { Button } from "../../../lib/components/buttons/Button";
import "./UnavailableService.css";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

interface IUnavailableServiceProps {
  errorText?: string;
}

export function UnavailableService(props: IUnavailableServiceProps) {
  const navigate = useNavigate();

  return (
    <div className="unavailable-service">
      <div className="wrapper">
        <div className="error-text">
          <div className="error">
            <IconWarning />
          </div>
          <div className="info">
            <div className="title">
              Ooops!
              <br />
              {translate("COMMON.ERRORPAGES.SomethingWrong")}
            </div>
            <div className="text">{props.errorText}</div>
          </div>
        </div>
        <Button
          className="error-page-btn"
          type="primary"
          text={translate("COMMON.ERRORPAGES.BackToHome")}
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
}
