import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../lib/components/buttons/Button";
import "./ErrorPage.css";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

export function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="wrapper">
        <div className="error-text">
          <div className="error">404</div>
          <div className="info">
            <div className="title">
              Ooops!
              <br />
              {translate("COMMON.ERRORPAGES.PageNotFound")}
            </div>
            <div className="text">
              {translate("COMMON.ERRORPAGES.PageNotFoundText")}
            </div>
          </div>
        </div>
        <Button
          className="error-page-btn"
          type="primary"
          text={translate("COMMON.ERRORPAGES.BackToHome")}
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
}
