import { ChangeEvent, useCallback, useRef } from "react";

import { Button } from "../buttons/Button";

export interface IFileUploadButtonProps {
  text: string;
  type: "primary" | "secondary" | "tertiary";
  icon?: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onFileSelected: (f: File) => void;
}

export function FileUploadButton(props: IFileUploadButtonProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      inputRef.current?.click();
    },
    [inputRef, props.isDisabled]
  );


  const handleFileChange = useCallback( (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    props.onFileSelected(e.target.files[0]);

    if(inputRef.current)
        inputRef.current.value = "";
  }, [inputRef]);

  return (
    <>
    <Button {...props} onClick={handleClicked} />

    <input
        title="hidden-upload-input"
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  );
}
