import "./MaterialsHomePage.css";

import {
  ColumnDefinition,
  IOrderInfo,
} from "../../../lib/components/table/TableInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { AddMaterialPopup } from "./AddMaterialPopup";
import { Button } from "../../../lib/components/buttons/Button";
import { ReactComponent as CheckIcon } from "../../../lib/assets/icons/check-btn.svg";
import { ConditionalRender } from '../../../lib/functional/ConditionalRender';
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ContextualMenu } from "../../../lib/components/contextual-menu/ContextualMenu";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { EditMaterialPopup } from "./EditMaterialPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FileUploadButton } from "../../../lib/components/file/FileUploadButton";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from '../../../lib/components/loader/FullScreenLoader';
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import-dark.svg";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { Material } from '../../../models/domain/materials/Material';
import { MaterialsQueryOptions } from "../../../models/api/materials/MaterialsQueryOptions";
import { MaterialsService } from "../../../services/MaterialsService";
import { ReactComponent as MoreActions } from "../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useParams } from "react-router-dom";

/***** ICONS ******/


var materialService = new MaterialsService();

export function MaterialsHomePage() {
  const { id: locationId } = useParams();

  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();
  const windowResize = useWindowResize();

  const [materials, setMaterials] = useState<Material[]>();
  const [totalMaterials, setTotalMaterials] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchWord, setSearchWord] = useState<string>();

  const [currentpage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>();


  const [isLoadingImport, setIsLoadingImport] = useState(false);

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  var searchBoxFormControl = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });



  useEffect(() => {
    getMaterials();
  }, [currentpage, itemsPerPage, searchWord]);



  // Timer para chamar a API, sempre que há uma mudança na search-box.
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {

        setSearchWord(searchBoxFormControl.value);
        setCurrentPage(0);

      }
    }, 1000); // tempo de espera em milisegundos, antes de lançar o pedido à API.

    setTimer(responseTimeOut);
  }, [searchBoxFormControl.value]);



  const getMaterials = useCallback(() => {
    if (locationId) {
      setIsLoading(true);

      var queryOptions: MaterialsQueryOptions = {
        page: currentpage,
        pageLength: itemsPerPage,
        searchWord: searchWord,
        filterByIsActive: false,
      };

      if (locationId) {
        materialService
          .getMaterials(locationId, queryOptions)
          .then((response) => {
            setMaterials(response.materials);
            setTotalMaterials(response.totalMaterials);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
          });
      }
    }
  }, [
    locationId,
    setMaterials,
    setTotalMaterials,
    setIsLoading,
    currentpage,
    itemsPerPage,
    searchWord,
  ]);



  const handleFileImport = useCallback((file: File) => {
    if (!locationId) return;

    setIsLoadingImport(true);
    materialService.uploadMaterialFile(locationId, file).then(res => {
      setIsLoadingImport(false);
      getMaterials();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate(
            "GLOBALADMIN.MATERIALS.POPUP.ImportFileSuccess"
          )}
        ></Notification>
      );
    }).catch(error => {
      setIsLoadingImport(false)
      openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
    });


  }, [locationId, createNotification, openPopup, setIsLoadingImport, getMaterials]);

  const handleOnOrderChanged = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderedPriorities);
    },
    [setOrderInfoColumns]
  );



  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );



  const onClickDisableMaterial = useCallback((materialID: number) => {

    if (materialID && locationId) {
      materialService.disableMaterial("" + locationId, materialID)
        .then((_) => {
          getMaterials();
          closePopup();
          createNotification(
            <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.MATERIALS.POPUP.DisableMaterialSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationId, getMaterials, createNotification, openPopup, closePopup, translate])



  const onClickEnableMaterial = useCallback((materialID: number) => {

    if (materialID && locationId) {
      materialService.enableMaterial("" + locationId, materialID)
        .then((_) => {
          getMaterials();
          closePopup();
          createNotification(
            <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.MATERIALS.POPUP.ActivateMaterialSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationId, getMaterials, createNotification, openPopup, closePopup, translate])



  const renderEditMaterialPopup = useCallback((material: Material) => {
    if (locationId && material.id)
      return openPopup(
        <EditMaterialPopup
          onChangesMade={getMaterials}
          locationId={"" + locationId}
          materialId={material.id}
        ></EditMaterialPopup>
      );
    return null;
  }, [openPopup, getMaterials, locationId]);



  const materialColumnsList: ColumnDefinition<Material>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.plant,
        width: "10%",
        headerRender: translate("GLOBALADMIN.MATERIALS.INFO.Plant"),
        columnKey: "plant",
        isSortable: false,
      },
      {
        cellRenderProp: (v) => v.name,
        width: "25%",
        headerRender: translate("GLOBALADMIN.MATERIALS.INFO.Code"),
        columnKey: "name",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.description,
        width: "40%",
        headerRender: translate("GLOBALADMIN.MATERIALS.INFO.Description"),
        columnKey: "description",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.isSubjectToC04 ? <CheckIcon className="check-icon" /> : null,
        width: "20%",
        headerRender: translate("GLOBALADMIN.MATERIALS.INFO.SubjectToC04"),
        columnKey: "description",
        isSortable: false,
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            <Toggle value={!v.isDisabled} />
            <div
              className="toggle-div-overlay"
              onClick={(ev) => {
                ev.stopPropagation();
                if (!v.isDisabled) {
                  openPopup(
                    <WarningPopup
                      className="disable-material-popup"
                      onDismissClicked={() => {
                        closePopup();
                      }}
                      onConfirmClicked={() => {
                        onClickDisableMaterial(v.id);
                      }}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate(
                        "GLOBALADMIN.MATERIALS.POPUP.Deactivate"
                      )}
                    >
                      {translate(
                        "GLOBALADMIN.MATERIALS.POPUP.DeactivateMaterialConfirmText"
                      )}
                    </WarningPopup>
                  );
                }
                else {
                  openPopup(
                    <WarningPopup
                      onDismissClicked={() => {
                        closePopup();
                      }}
                      onConfirmClicked={() => {
                        onClickEnableMaterial(v.id);
                      }}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate(
                        "COMMON.Activate"
                      )}
                    >
                      {translate(
                        "GLOBALADMIN.MATERIALS.POPUP.ActivateMaterialConfirmText"
                      )}
                    </WarningPopup>
                  );
                }
              }}
            />
          </div>
        ),
        width: "10%",
        columnKey: "status",
        isSortable: false,
        isMobileHeaderIcon: true
      },
      {
        cellRenderProp: (v) => (
          <ScalableIconButton
            icon={<EditIcon />}
            onClick={() => {
              renderEditMaterialPopup(v);
            }}
            size={30}
          />
        ),

        width: "5%",
        columnKey: "stats",
        isSortable: false,
        isMobileHeaderIcon: true
      },

    ],
    [openPopup, closePopup, onClickDisableMaterial, renderEditMaterialPopup, onClickEnableMaterial]
  );

  
  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return <>
        <Button
          text={translate("COMMON.Import")}
          type="secondary"
          onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

            window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/LaMP_Materials_Template.xlsx", "_blank")}

            onClickImportBtn={handleFileImport} />)}
          className="import-material"
        />
        <Button
          text={translate("COMMON.Create")}
          type="primary"
          onClick={() => {
            if (locationId) {
              openPopup(
                <AddMaterialPopup
                  locationId={"" + locationId}
                  onChangesMade={getMaterials}
                />
              );
            }
          }}
          className="create-material"
        ></Button>
      </>
    else
      return <>
        <ContextualMenu
          items={[
            {
              label: translate("COMMON.Import"),
              icon: <ImportIcon />,
              navigateTo: "",
              onClick: () => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>
                window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/LaMP_Materials_Template.xlsx", "_blank")}
                onClickImportBtn={handleFileImport} />)
            },
          ]}
        >
          <ScalableIconButton size={30} icon={<MoreActions />} />
        </ContextualMenu >
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => {
            if (locationId) {
              openPopup(
                <AddMaterialPopup
                  locationId={"" + locationId}
                  onChangesMade={getMaterials}
                />
              );
            }
          }}
        />
      </>
  }, [windowResize, getMaterials, handleFileImport, locationId, openPopup])

  return (
    <PageLayout
      tabTitle={translate("GLOBALADMIN.MATERIALS.Materials")}
      pageTitle={translate("GLOBALADMIN.MATERIALS.Materials")}
      className="materials-home"
      showBreadcrumb
      actions={renderActionButtons}
    >
      <FormFieldTextInput
        formControl={searchBoxFormControl}
        icon={<SearchIcon />}
        placeholder={translate("COMMON.FORM.SearchPlaceholder")}
        className="search-box"
      />
      <ResponsiveDataTable
        columnDefinitions={materialColumnsList}
        items={materials || []}
        totalitems={totalMaterials || 0}
        paginationOptions={{
          itemsPerPage: itemsPerPage,
          itemsPerPageOptions: [10, 20, 30, 40, 50],
        }}
        currentpage={currentpage}
        isLoading={isLoading}
        onOrderChanged={handleOnOrderChanged}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onClickRow={renderEditMaterialPopup}
      ></ResponsiveDataTable>

      <ConditionalRender if={isLoadingImport} >
        <FullScreenLoader />
      </ConditionalRender>
    </PageLayout>
  );
}
