import "./EditLaboratoryEmailPopup.css";

import { useCallback, useEffect } from "react";
import { LaboratoryEmailsService } from "../../../../services/LaboratoryEmailsService";
import { useServiceCallPro } from "../../../../services/UseServiceCall";
import { useFormControl } from "../../../../lib/components/form/Form";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { regexMultipleEmailsSeparatedByComma, validateRegexOrEmpty } from "../../../../lib/validators/ValidateRegex";
import { LaboratoryEmailsUpdateDTO } from "../models/LaboratoryEmailsUpdateDTO";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";


function cleanEmailString(emailString: string): string {
    return emailString
        .trim()
        .replace(/\s*;\s*/g, '; ')
        .replace(/\s+/g, ' ');
}



var svc = new LaboratoryEmailsService();

export interface EditLaboratoryEmailPopupProps {
    locationId: string;
    onCompletedOperations: () => void;
}

export function EditLaboratoryEmailPopup(props: EditLaboratoryEmailPopupProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const getLaboratoryEmailsCall = useServiceCallPro(svc.getLocationLaboratoryEmails);
    const updateLaboratoryEmailsCall = useServiceCallPro(svc.editLocationLaboratoryEmails);

    /* ----------------------------------------------------------------------------------------------------------------- */

    const emailsFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexMultipleEmailsSeparatedByComma, true, "GLOBALADMIN.EMAILS.InputEmailValidation")],
        enableAutoValidate: true,
    });


    const getLaboratoryEmails = useCallback(() => {

        if (!props.locationId) return;

        getLaboratoryEmailsCall.invoke(props.locationId)
            .then((response) => {
                emailsFormControl.setValue(response.emails);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>, false);
            })
    }, [getLaboratoryEmailsCall.invoke, props.locationId, emailsFormControl.setValue, openPopup]);





    const updateLaboratoryEmails = useCallback(() => {
        if (!props.locationId || !emailsFormControl.validate()) return;

        var request: LaboratoryEmailsUpdateDTO = {
            emails: ""
        };

        request.emails = cleanEmailString(emailsFormControl.value ?? "");

        updateLaboratoryEmailsCall.invoke(props.locationId, request)
            .then((_) => {
                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.EMAILS.UpdateEmailDetailsSuccessMessage")}
                    ></Notification>
                );
                closePopup();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>, false);
            })
    }, [updateLaboratoryEmailsCall.invoke, props.locationId, props.onCompletedOperations, emailsFormControl.validate,
    emailsFormControl.value, openPopup, closePopup]);




    /****************************
    * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getLaboratoryEmails();
    }, []);




    /****************************
     * USER ACTIONS
     *****************************/

    const editLaboratoryEmailButtonClicked = useCallback(() => {
        updateLaboratoryEmails();
    }, [updateLaboratoryEmails]);




    /****************************
     * CSS & HTML
     *****************************/

    return (
        <PopupContainer className="popup-edit-laboratory-emails">
            <PopupHeader title={translate("GLOBALADMIN.EMAILS.UpdateEmailDetails")} />
            {updateLaboratoryEmailsCall.isLoading && <FullScreenLoader />}

            <PopupContent isLoading={getLaboratoryEmailsCall.isLoading}>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={emailsFormControl}
                        label={translate("GLOBALADMIN.EMAILS.Emails")}
                        placeholder="Ex: lab_email001@valmet.com"
                    />
                </FormContainer>


                <Spacer mode={"vertical"} px="20"></Spacer>


                <div className="small-copy instruction">{translate("GLOBALADMIN.EMAILS.UpdateEmailsInstruction")}</div>
                <div className="small-copy instruction">{translate("GLOBALADMIN.EMAILS.UpdateEmailsInstructionExample")}</div>

                <Spacer mode={"vertical"} px="20"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: editLaboratoryEmailButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}