import "./FormContainer.css";

export interface IFormContainerProps {
    children: React.ReactNode
    mode?: "horizontal" | "vertical"
}

export function FormContainer(props: IFormContainerProps) {

    return <div className="form-container">
        {props.children}
    </div>
}
