import "./Notification.css";

import { useCallback, useEffect, useState } from "react";

import { ReactComponent as NotificationCloseIcon } from "../../assets/icons/notification-close-icon.svg";
import { ReactComponent as NotificationErrorIcon } from "../../assets/icons/notification-error-icon.svg";
import { ReactComponent as NotificationSuccessIcon } from "../../assets/icons/notification-success-icon.svg";

// import { useCloseNotification } from "../../infrastructure/ui/UIServices";

interface INotificationProps {
  type: string;
  icon?: React.ReactNode;
  title: string;
  text: string;
}

export function Notification(props: INotificationProps) {
  // const closeNotification = useCloseNotification();

  const [onRenderClass, setOnRenderClass] = useState<boolean>(false);

  useEffect(() => {
    setOnRenderClass(true);
    setTimeout(() => {
      setOnRenderClass(false);
      // setTimeout(() => closeNotification(), 400);
    }, 5000);
  }, [setOnRenderClass]);

  const _notificationIcon = useCallback(() => {
    switch (props.type) {
      case "success":
        return <NotificationSuccessIcon />;
      case "error":
        return <NotificationErrorIcon />;
      case "other":
        return props.icon;
    }
  }, [props.type, props.icon]);

  return (
    <div className={"notification " + (onRenderClass ? "show" : "hide")}>
      <div className={"notification-icon " + props.type}>{_notificationIcon()}</div>
      <div className="notification-info">
        <div className="notification-info-title">{props.title}</div>
        <div className="notification-info-text">{props.text}</div>
      </div>
      <div
        className="notification-close"
        onClick={() => {
          setOnRenderClass(false);
          // setTimeout(() => closeNotification(), 400);
        }}
      >
        <NotificationCloseIcon />
      </div>
    </div>
  );
}
