import "./Toggle.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";

export interface ITooggleProps {
    isDisabled?: boolean;
    value?: boolean;
    className?: string;
    onToggleChanged?: (checked: boolean) => void;
}

export function Toggle(props: ITooggleProps) {

    const [isActive, setIsActive] = useState(props.value);

    useEffect(() => {
        if(props.value == undefined) return;
        setIsActive(props.value);
    }, [props.value]);

    const toggleCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("toggle")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(isActive, "active")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.isDisabled, isActive]);


    const handleClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (props.isDisabled) return;
            setIsActive(!isActive);
            props.onToggleChanged && props.onToggleChanged(!isActive);
        },
        [props.onToggleChanged, props.isDisabled, setIsActive, isActive]
    );

    return (
        <div className={toggleCss} onClick={handleClicked} >
            <div className="slider" />
        </div>
    );
}