import "./MobileSubSidebar.css";

import { Avatar } from "../avatar/Avatar";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { MenuItem } from "./MenuItem";
import { SubSideBarButton } from "./SubSideBarButton";
import { useMemo } from "react";

export interface SubSidebarUser {
  name: string;
  jobTitle: string;
}

export interface ISideBarProps {
  className?: string;
  buttons: MenuItem[];
  user?: SubSidebarUser;
}

export function MobileSubSidebar(props: ISideBarProps) {
  const sidebarCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("mobile-sub-side-bar")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const renderUserInfo = useMemo(() => {
    if (props.user)
      return <div className="sub-side-bar-user">
        <Avatar name={props.user.name} className="user-avatar" />
      </div>;
  }, [props.user]);

  const renderSideBarButtons = useMemo(() => {
    return <div className="sub-side-bar-btns">{props.buttons.map((m, idx) => {
      return (
        <SubSideBarButton
          key={idx}
          icon={m.icon}
          navigateTo={m.navigateTo}
          name={m.name}
        />
      );
    })}</div>;
  }, [props.buttons]);

  return <div className={sidebarCss}>
    {renderUserInfo}
    {renderSideBarButtons}
  </div>;
}
