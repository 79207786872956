import "./DotsLoader.css";

export function DotsLoader() {
  return (
    <div>
      <span className="loader__dot">.</span>
      <span className="loader__dot">.</span>
      <span className="loader__dot">.</span>
    </div>
  );
}
