
interface IConditionalRenderProps {
    children: React.ReactNode;
    if: boolean;
}

export function ConditionalRender(props: IConditionalRenderProps) {

 
    if(props.if) {
        return <>{props.children}</>;
    }

    return null;
}
