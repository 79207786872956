import { CancelHttpEvent } from "../../../events/CancelHttpEvent";
import { IdentityApp } from "../identity/components/IdentityApp";
import { LocationServices } from "../location/LocationServices";
import { NavigationServices } from "../navigation/NavigationServices";
import { UISContextProvider } from "../ui/UIServices";
import { cancelationToken } from "../http/AddAxiosInterceptor";
import { useEffect } from "react";

interface ISPAAppProps {
  children: React.ReactNode;
}

export function SPAApp(props: ISPAAppProps) {
  useEffect(() => {
    const cancelHttpEvents = () => {
      cancelationToken.cancel("Request cancel all events");
    };

    CancelHttpEvent.attachHandler(cancelHttpEvents);
    return () => CancelHttpEvent.detachHandler(cancelHttpEvents);
  }, []);
  return (
    <IdentityApp>
      <UISContextProvider>
        <NavigationServices>
          <LocationServices>
            {props.children}
          </LocationServices>
        </NavigationServices>
      </UISContextProvider>
    </IdentityApp>
  );
}
