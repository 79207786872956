import "./AccessDeniedPage.css";

import { Button } from "../../../lib/components/buttons/Button";
import { ReactComponent as CloseIcon } from "../../../lib/assets/icons/close-icon.svg";
import React from "react";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useNavigate } from "react-router-dom";

export function AccessDeniedPage() {
  const navigate = useNavigate();

  return (
    <div className="access-denied-page">
      <div className="wrapper">
        <div className="access-denied-text">
          <div className="icon">
            <CloseIcon />
          </div>
          <div className="info">
            <div className="title">
              {translate("COMMON.ERRORPAGES.AccessDenied")}
            </div>
            <div className="text">
              {translate("COMMON.ERRORPAGES.AccessDeniedText1")}
              <br />
              {translate("COMMON.ERRORPAGES.AccessDeniedText2")}
            </div>
          </div>
        </div>
        <div className="access-denied-btns">
          <Button
            className="back-home-btn"
            type="primary"
            text={translate("COMMON.ERRORPAGES.BackToHome")}
            onClick={() => navigate("/")}
          />
          <Button
            className="logout-btn"
            type="primary"
            text={translate("COMMON.Logout")}
            onClick={() => navigate("/identity/logout")}
          /></div>
      </div>
    </div>
  );
}
