import "./MiniDropdown.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as ArrowDownSVG } from "../../../assets/icons/arrowDown.svg";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import React from "react";
import { ReactComponent as SelectedOptionSVG } from "../../../assets/icons/check.svg";
import { TextButton } from "../../buttons/TextButton";
import removeAccents from "remove-accents";
import { useOutsideComponentClickTrigger } from "../../../hooks/useOutsideComponentClickTrigger";

interface IMiniDropdownProps<T> {
  value?: T; // TODO: Hadle Initial Value
  options: T[];
  labelSelector: (value: T) => string;
  idSelector: (value: T) => string | number;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  maxHeightOptions?: string;
  onChange?: (value?: T) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

interface ISelectSingleOption<T> {
  value?: T;
  id: string | number;
  label: string;
  normalizedLabel: string;
}

function calcSelectedOptionLength(value: string) {
  return value?.length + 8;
}

export function MiniDropdown<T>(props: IMiniDropdownProps<T>) {
  const nativeInputRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState<string>();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>();
  const [selectedLabel, setSelectedLabel] = useState<string>();
  // const [mode, setMode] = useState<"value" | "search">("value");
  const [highlightedOption, setHighlightedOption] = useState<number>(-1);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  /*   const placeHolder = useMemo(
      () => (props.placeholder !== undefined ? props.placeholder : "Type Here"),
      [props.placeholder]
    ); */

  const options = useMemo(() => {
    let mappedOptions = props.options.map(
      (opt: T): ISelectSingleOption<T> => ({
        value: opt,
        label: props.labelSelector(opt),
        id: props.idSelector(opt),
        normalizedLabel: removeAccents(props.labelSelector(opt).toLowerCase()),
      })
    );
    return mappedOptions;
  }, [props.options, props.idSelector, props.labelSelector]);

  useEffect(() => {
    setHighlightedOption(-1);
  }, [isPanelOpen]);

  useEffect(() => {
    let id = props.value && props.idSelector(props.value);

    if (id !== selectedId) {
      let label = props.value && props.labelSelector(props.value);
      setSelectedId(id);
      setSelectedLabel(label);
      setInputValue(label);
    }
  }, [options, props.value]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSelectSingleClicked = useCallback(() => {
    if (!props.isDisabled) {
      setIsPanelOpen(!isPanelOpen);
      nativeInputRef.current?.focus();
    }
  }, [setIsPanelOpen, isPanelOpen, props.isDisabled]);

  const handleItemSelected = useCallback(
    (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent> | null,
      optionId: string | number,
      option: ISelectSingleOption<T>
    ) => {
      ev?.stopPropagation();

      setSelectedId(optionId);
      setSelectedLabel(option.label);
      setInputValue(option.label);

      props.onChange && props.onChange(option.value);
      setIsPanelOpen(false);
    },
    [
      props.onChange,
      setIsPanelOpen,
      setSelectedId,
      setSelectedLabel,
      setInputValue,
    ]
  );

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.defaultPrevented) return;

      switch (ev.key) {
        case "Escape":
        case "Tab":
          setIsPanelOpen(false);
          nativeInputRef.current?.blur();
          break;

        case "ArrowDown":
          if (highlightedOption < options.length - 1)
            setHighlightedOption(highlightedOption + 1);
          break;

        case "ArrowUp":
          if (highlightedOption > 0)
            setHighlightedOption(highlightedOption - 1);
          break;

        case "Enter":
          if (highlightedOption >= 0) {
            let option = options.at(highlightedOption);
            if (option)
              handleItemSelected(null, option?.id || highlightedOption, option);
            setIsPanelOpen(false);
            nativeInputRef.current?.blur();
          }
          break;
      }
    },
    [
      setIsPanelOpen,
      highlightedOption,
      setHighlightedOption,
      options,
      handleItemSelected,
    ]
  );

  useOutsideComponentClickTrigger(componentRef, () => {
    setInputValue(selectedLabel);
    setIsPanelOpen(false);
  });

  /****************************
   * CSS & HTML
   *****************************/

  const maxHeightOptions = useMemo((): React.CSSProperties | undefined => {
    if (props.maxHeightOptions) {
      return { maxHeight: props.maxHeightOptions };
    } else return undefined;
  }, [props.maxHeightOptions]);

  const selectSingleCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("mini-dropdown")
      .addConditional(props.className, props.className)
      .addConditional(props.hasError, "error")
      .addConditional(props.isDisabled, "disabled")
      .build();
  }, [props.className, props.hasError, props.isDisabled]);

  const optionsHTML = useMemo(
    () =>
      options.map((opt, idx) => {
        const id = opt.id;

        const isSelected = selectedId === id;

        const css = CssClassnameBuilder.new()
          .add("mini-dropdown-option")
          .addConditional(isSelected, "selected")
          .addConditional(highlightedOption === idx, "hover")
          .build();

        return (
          <div
            key={id}
            className={css}
            onClick={(ev) => handleItemSelected(ev, id, opt)}
          >
            <span>{opt.label}</span>

            {isSelected ? <SelectedOptionSVG className="check-option" /> : null}
          </div>
        );
      }),
    [handleItemSelected, selectedId, options, highlightedOption]
  );

  const selectedOptionLenght = useMemo(() => {
    return calcSelectedOptionLength(selectedLabel || "");
  }, [selectedLabel]);

  return (
    <div
      ref={componentRef}
      className={selectSingleCss}
      onClick={handleSelectSingleClicked}
      style={{ width: selectedOptionLenght + "ch", maxWidth: 20 + "ch" }}
    >
      <input
        ref={nativeInputRef}
        className="native-input"
        disabled={props.isDisabled}
        type="text"
        value={inputValue}
        onChange={(ev) => {
          setInputValue(ev.target.value);
        }}
        onKeyDown={handleKeyDown}
        readOnly={true}
      ></input>
      <ArrowDownSVG className="arrow-down-icon" />

      {isPanelOpen ? (
        <div className="mini-dropdown-options-panel" style={maxHeightOptions}>
          {optionsHTML}
        </div>
      ) : null}
    </div>
  );
}
