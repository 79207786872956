import { useCallback, useMemo, useRef } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { TableCell } from "./table-cell/TableCell";

interface ITableProps {
  className?: string;
  children: React.ReactNode;
}

export function Table(props: ITableProps) {
  const tableRef = useRef<HTMLTableElement>(null);
  const tableCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("table-container")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  return (
    <div className={tableCss}>
      <table ref={tableRef}>
        {props.children}
      </table>
    </div>
  );
}


interface ITableHeaderProps {
    className?: string;
    children: React.ReactNode;
  }


export function TableHeader(props: ITableHeaderProps) {

    return (
        <thead>
            <tr>{props.children}</tr>
        </thead>
    )
}

interface ITableHeaderCellProps {
  children: React.ReactNode;
  className?: string;
  key?: any
}



export function TableHeaderCell(props: ITableHeaderCellProps) {




      const tableColumnHeaderCss = CssClassnameBuilder.new()
        .add("table-column-header")
        .addConditional(props.className, props.className)
        .build();

      return (
        <th key={props.key}>
          <div
            className={tableColumnHeaderCss}

          >
            {props.children}
          </div>
        </th>
      );
}



interface ITableBodyProps {
    className?: string;
    children: React.ReactNode;
  }


export function TableBody(props: ITableBodyProps) {

    return (
        <tbody>
            {props.children}
        </tbody>
    )
}




export interface ITableRowProps {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
  }

  export function TableRow(props: ITableRowProps) {

    const tableRowCss = useMemo(() => {
      return CssClassnameBuilder.new()
        .add("table-row")
        .addConditional(props.className, props.className)
        .build();
    }, [props.className]);


    const handleRowClick = useCallback(() => {
      const screenSelectedText = window.getSelection()?.toString();
      if (screenSelectedText === "") {
        props.onClick && props.onClick();
      }
    }, [props.onClick]);



    return (
      <tr className={tableRowCss} onClick={handleRowClick}>
        {props.children}
        </tr>
    );
  }





  interface ITableRowCellProps {
    children: React.ReactNode;
    className?: string;
    key?: any
  }



  export function TableRowCell(props: ITableRowCellProps) {


    const tableCellCss = useMemo(() => CssClassnameBuilder.new()
      .addConditional(props.className, props.className)
      .add("table-row-cell")
      .build(), [props.className]);


  return (
    <TableCell className={tableCellCss} key={props.key}>
      {props.children}
    </TableCell>
  );
  }
