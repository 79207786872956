import { ConfigurationProvider } from "../configuration/ConfigurationProvider";
import { AddAxiosInterceptors } from "../http/AddAxiosInterceptor";
import { InternationalizationProvider } from "../i18n/InternationalizationProvider";
import { IdentityProvider } from "../identity/IdentityProvider";

export class Startup {
  static async Run(app: () => void) {

  
    AddAxiosInterceptors();
    
    await ConfigurationProvider.init();

    await Promise.all([
      IdentityProvider.init(),
      InternationalizationProvider.init()
    ]);

    app();
  }
}
