import "./MobileTableCell.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";

export interface IMobileTableCellProps {
  className?: string;
  children?: React.ReactNode;
  label?: React.ReactNode;
  isMobilePrimary?: boolean;
  isMobileIcon?: boolean;
  isStatusCell?: boolean,
  isVisible?: boolean
}

export function MobileTableCell(props: IMobileTableCellProps) {
  const tableCellCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("mobile-table-cell-value")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  return (
    <div className="mobile-table-cell">
      {props.label ? <div className="small-copy mobile-table-cell-label">{props.label}</div> : null}
      <div className={tableCellCss}>{props.children}</div>
    </div>
  );
}
