import "./TableSelectSingle.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as ArrowSVG } from "../../../assets/icons/arrowDown.svg";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { useOutsideComponentClickTrigger } from "../../../hooks/useOutsideComponentClickTrigger";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

interface ITableSelectSingleProps {
  value: number;
  options: number[];
  onChange?: (option: number) => void;
}

export function TableSelectSingle(props: ITableSelectSingleProps) {
  const nativeInputRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedLabel, setSelectedLabel] = useState<number>();
  const [mode, setMode] = useState<"value" | "search">("value");
  const [highlightedOption, setHighlightedOption] = useState<number>(-1);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {
    setHighlightedOption(-1);
    if (mode === "search") {
      setIsPanelOpen(true);
    } else {
      setIsPanelOpen(false);
    }
  }, [mode]);

  useEffect(() => {
    setSelectedId(props.value);
    setSelectedLabel(props.value);
  }, [props.value]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleTableSelectSingleClicked = useCallback(() => {
    setMode("search");
  }, [setMode]);

  const handleItemSelected = useCallback(
    (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent> | null,
      option: number
    ) => {
      ev?.stopPropagation();

      setSelectedId(option);
      setSelectedLabel(option);

      props.onChange && props.onChange(option);
      setMode("value");
    },
    [props.onChange, setMode]
  );

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.defaultPrevented) return;

      switch (ev.key) {
        case "Escape":
        case "Tab":
          setMode("value");
          nativeInputRef.current?.blur();
          break;

        case "ArrowDown":
          if (highlightedOption < props.options.length - 1)
            setHighlightedOption(highlightedOption + 1);
          break;

        case "ArrowUp":
          if (highlightedOption > 0)
            setHighlightedOption(highlightedOption - 1);
          break;

        case "Enter":
          if (highlightedOption >= 0) {
            let option = props.options.at(highlightedOption);
            if (option) handleItemSelected(null, option);
            setMode("value");
            nativeInputRef.current?.blur();
          }
          break;
      }
    },
    [
      setMode,
      props.onChange,
      highlightedOption,
      setHighlightedOption,
      props.options,
    ]
  );

  useOutsideComponentClickTrigger(componentRef, () => {
    setMode("value");
  });

  /****************************
   * CSS & HTML
   *****************************/

  const optionsHTML = useMemo(
    () =>
      props.options.map((opt, idx) => {
        const css = CssClassnameBuilder.new()
          .add("single-select-option")
          .addConditional(selectedId === opt, "selected")
          .addConditional(highlightedOption === idx, "hover")
          .build();

        return (
          <div
            key={idx}
            className={css}
            onClick={(ev) => handleItemSelected(ev, opt)}
          >
            {opt}
          </div>
        );
      }),
    [handleItemSelected, selectedId, props.options, highlightedOption]
  );

  return (
    <div
      ref={componentRef}
      className={"table-select-single"}
      onClick={handleTableSelectSingleClicked}
    >
      <div className="table-select-single-label">
        {translate("COMMON.TABLE.RowsPerPage")}
      </div>
      <div className="table-select-container">
        <div
          ref={nativeInputRef}
          className="native-input"
          onKeyDown={handleKeyDown}
        >
          {selectedLabel}
        </div>
        <ArrowSVG className="rotate" />

        {isPanelOpen ? (
          <div className="table-select-single-options-panel">{optionsHTML}</div>
        ) : null}
      </div>
    </div>
  );
}
