
import { validate as isGuidValid } from "uuid";
import { Optional, ValidatorFunction } from "../components/form/Form";
import { translate } from "../infrastructure/i18n/InternationalizationService";

export function validateGuid(): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if(!value){
            return null; 
        }
        if (!isGuidValid(value)) {
            return translate("COMMON.FORM.VALIDATIONS.GuidValidator");
        }
        return null;
    };
}
