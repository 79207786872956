import { useContext, useMemo } from "react";

import { UISContext } from "./UIServices";

export function useParentEventListener() {


    const context = useContext(UISContext);

    if (!context) throw new Error("erro");

    const parentEventListener = useMemo(() => {
        return context.showPopup ? context.popupContainerRef?.current : window;
    }, [context.showPopup, context.popupContainerRef, context.popupContainerRef.current]);


    return parentEventListener;
}
