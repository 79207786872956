import "./InfoBlock.css";

interface IInfoBlockProps {
  label: string | "";
  value: string | "";
}

export function InfoBlock(props: IInfoBlockProps) {
  return (
    <div className="info-block">
      <div className="info-block-label">{props.label}</div>
      <div className="info-block-value">{props.value}</div>
    </div>
  );
}
