import { useEffect } from "react";
import { useParentEventListener } from "../infrastructure/ui/useParentEventListener";

export function useOutsideComponentClickTrigger(ref: any, func: Function) {

    const observer = useParentEventListener();



    useEffect(() => {
        const targetObserver = observer || window;
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                func()
            }
        }

        targetObserver.addEventListener("mousedown", handleClickOutside);
        return () => targetObserver.removeEventListener("mousedown", handleClickOutside);
    }, [ref, func, observer]);
}

