import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { PermissionsDTO } from "../models/api/gate/PermissionsDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class GateService {

  getUserPermissions(): Promise<PermissionsDTO> {
    return HttpClient.sessionRequest<PermissionsDTO>({
      method: "GET",
      url: Route(`/api/v1/Gate/UserPermissions`),
    })
      .then((response) => {
        return response.data;
      }).catch((error) => 
      { 
        throw error
       }
      );
      

  }
}
