import { DatePicker } from "../datepicker/DatePicker";
import { DateTime } from "luxon";
import { FormField } from "./FormField";
import { IFormControl } from "../Form";

interface IFormFieldDatePickerProps {
  label?: string;
  formControl: IFormControl<DateTime>;
  /* selectedDate?: DateTime; */
  placeholder?: string;
  className?: string;
  hasMinSelectableDate?: boolean;
  minDate?: DateTime | undefined;
  maxDate?: DateTime | undefined;
  alternativeStyle?: boolean;
}

export function FormFieldDatePicker(props: IFormFieldDatePickerProps) {
  return (
    <FormField label={props.label} formControl={props.formControl}>
      <DatePicker
        value={props.formControl.value}
        onChange={(value) => props.formControl.setValue(value)}
        placeholder={props.placeholder}
        className={props.className}
        isDisabled={props.formControl.isDisabled}
        hasError={props.formControl.hasErrors}
        minDate={props.minDate}
        maxDate={props.maxDate}
        alternativeStyle={props.alternativeStyle}
      />
    </FormField>
  );
}
