import "./PopupContainer.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";
import { CssHelper } from "../../../utils/CssHelper";
import { useWindowResize } from "../../../infrastructure/ui/UIServices";

interface IPopupProps {
  children: React.ReactNode;
  className?: string;
  desktopPxWidth?: string;
}

export function PopupContainer(props: IPopupProps) {
  const windowResize = useWindowResize();

  const popupContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("popup-container")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const popupWidth = useMemo(() => {
    if (props.desktopPxWidth && windowResize > 768)
      return { width: CssHelper.pxToRem(props.desktopPxWidth) };
  }, [props.desktopPxWidth, windowResize]);

  return (
    <div className={popupContainerCss} style={popupWidth}>
      {props.children}
    </div>
  );
}
