import { InteractionStatus } from "@azure/msal-browser";
import { useMemo } from "react";
import { useMsal } from '@azure/msal-react';

export function useIdentityIsProcessing() {

    const {inProgress} = useMsal();


    const isProcessing = useMemo(() => {
        return inProgress === InteractionStatus.Startup
        || inProgress === InteractionStatus.HandleRedirect
        || inProgress === InteractionStatus.Login
    }, [inProgress])

    return isProcessing;
}
