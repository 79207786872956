import { IdentityClaims, IdentityHelper } from '../Identity.Helper';
import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { IdentityProvider } from '../IdentityProvider';
import { IdentityService } from '../IdentityService';
import { SilentRequest } from "@azure/msal-browser";

export function useIdentityUserDisplayName() {
    const [userDisplayName, setUserDisplayName] = useState<string | null>(null);
    const [userDisplayEmail, setUserDisplayEmail] = useState<string | null>(null);
    const [userDisplayNameIsLoading, setUserDisplayNameIsLoading] = useState(false);
    const [userDisplayNameError, setUserDisplayNameError] = useState(null);

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    useEffect(() => {
        setUserDisplayNameIsLoading(true);


        if(isAuthenticated){
            const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = instance.getAllAccounts();

            const request: SilentRequest = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0],
              forceRefresh: true,
            };

            instance.acquireTokenSilent(request)
            .then(authResult => {
                console.log("User Success")
                let claims = authResult.idTokenClaims || IdentityService.Global.getClaims();
                IdentityService.Global.saveClaims(claims as IdentityClaims);
                setUserDisplayName(IdentityHelper.getUserDisplayNameFromClaims(claims as IdentityClaims))
                setUserDisplayEmail(IdentityHelper.getUserEmailFromClaims(claims as IdentityClaims))
                setUserDisplayNameIsLoading(false);
                setUserDisplayNameError(null);
            })
            .catch((e) => {
                console.log("User Error", e)
                setUserDisplayName(null);
                setUserDisplayEmail(null);
                setUserDisplayNameIsLoading(false);
                setUserDisplayNameError(e);
            })
        } else {
            console.log("User Not Authenticated")
            setUserDisplayName(null);
            setUserDisplayEmail(null);
            setUserDisplayNameIsLoading(false);
            setUserDisplayNameError(null);
        }
    }, [isAuthenticated]);


    return {userDisplayName, userDisplayEmail, userDisplayNameIsLoading, userDisplayNameError};
}
