import { useMemo } from "react";
import "./Loader.css";

interface ILoaderProps {
  text?: string;
}

export function Loader(props: ILoaderProps) {
  const text = useMemo(() => {
    if (props.text) return <div className="loader-text">{props.text}</div>;
    else return null;
  }, [props.text]);

  return (
    <div className="loader-container">
      <div className="loader">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
      {text}
    </div>
  );
}
