import * as qs from "qs";

import { FacilitiesResponseDTO, FacilityDTO } from "../models/api/facilities/FacilitiesResponseDTO";

import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateFacilityRequestDTO } from "../models/api/facilities/CreateFacilityRequestDTO";
import { FacilitiesQueryOptions } from "../models/api/facilities/FacilitiesQueryOptions";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { UpdateFacilityRequestDTO } from "../models/api/facilities/UpdateFacilityRequestDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class FacilityService {

  getFacilities(locationId: string | number, queryOptions?: FacilitiesQueryOptions): Promise<FacilitiesResponseDTO> {

    return HttpClient.sessionRequest<FacilitiesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/Facilities`),
      params: queryOptions,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  disableFacility(locationId: string, facilityId: number): Promise<string> {
    return HttpClient.sessionRequest<string>({
      method: "DELETE",
      url: Route(`/api/v1/Locations/${locationId}/Facilities/${facilityId}`),
    })
      .then((response) => {
        return response.status.toString()
      })
      .catch((error) => {
        throw error;
      });
  }

  activateFacility(locationId: string, facilityId: number): Promise<string> {
    return HttpClient.sessionRequest<string>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/Facilities/${facilityId}/EnableFacility`),
    })
      .then((response) => {
        return response.status.toString();
      })
      .catch((error) => {
        throw error;
      });
  }

  getById(locationId: string, facilityId: string): Promise<FacilityDTO> {
    return HttpClient.sessionRequest<FacilityDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/Facilities/${facilityId}`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addFacility(request: CreateFacilityRequestDTO): Promise<FacilityDTO> {
    return HttpClient.sessionRequest<FacilityDTO>({
      method: "POST",
      url: Route(`/api/v1/Locations/${request.locationId}/Facilities`),
      data: request
    })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error;
      });
  }

  updateFacility(locationId: string, facilityId: string, facilitieName: string): Promise<void> {
    var request: UpdateFacilityRequestDTO = {
      name: facilitieName
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/Facilities/${facilityId}`),
      data: request
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error;
      });
  }
}

