import "./SystemPopup.css";

import { useCallback, useMemo } from "react";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { PopupContainer } from "../../layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../separator/Spacer";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-icon.svg";
import { useClosePopup } from "../../infrastructure/ui/UIServices";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { PopupActionButtons } from "../../layouts/containers/popup-buttons/PopupActionButtons";

interface IWarningPopupProps {
  children: React.ReactNode;
  className?: string;
  onDismissClicked?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirmClicked?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  stopClosePopupOnConfirmClicked?: boolean;
  dismissText?: string;
  confirmText?: string;
}

export function WarningPopup(props: IWarningPopupProps) {
  const closePopup = useClosePopup();

  const popupCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("system-popup")
      .add("warning-popup")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const handleDismissClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      props.onDismissClicked && props.onDismissClicked(ev);
      closePopup();
    },
    [props.onDismissClicked, closePopup]
  );

  const handleConfirmClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      props.onConfirmClicked && props.onConfirmClicked(ev);

      props.stopClosePopupOnConfirmClicked ?? closePopup();
    },
    [props.onDismissClicked, closePopup, props.stopClosePopupOnConfirmClicked]
  );

  const dismissText = useMemo(
    () => props.dismissText || translate("COMMON.Cancel"),
    [props.dismissText]
  );
  const confirmText = useMemo(
    () => props.confirmText || "Ok",
    [props.confirmText]
  );

  return (
    <PopupContainer className={popupCss}>
      <div className="popup-icon">
        <WarningIcon />
      </div>
      <Spacer px="14" mode="vertical" />
      <div className="popup-header">
        {translate("COMMON.SYSTEMPOPUPS.Warning")}
      </div>

      <Spacer px="6" mode="vertical" />
      <div className="popup-content">{props.children}</div>
      <Spacer mode={"vertical"} px="30" />
      <PopupActionButtons
        buttons={[
          {
            text: dismissText,
            type: "tertiary",
            onClick: handleDismissClicked,
          },
          {
            text: confirmText,
            type: "primary",
            onClick: handleConfirmClicked,
          },
        ]}
      />
    </PopupContainer>
  );
}
