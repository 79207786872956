import "./TextInput.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";

interface ITextInputProps<T> {
  value?: any;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  type?: "email" | "number" | "password" | "text";
  onChange?: (value: any) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  icon?: React.ReactNode;
  alternativeStyle?: boolean;
}

export function TextInput<T>(props: ITextInputProps<T>) {
  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange && props.onChange(ev.target.value);
    },
    [props.onChange]
  );

  const textInputCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("text-input")
      .addConditional(props.alternativeStyle, "alternative-style")
      .addConditional(props.className, props.className)
      .addConditional(props.hasError, "error")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.icon, "has-icon")
      .build();
  }, [
    props.className,
    props.hasError,
    props.isDisabled,
    props.icon,
    props.alternativeStyle,
  ]);

  return (
    <div className={textInputCss}>
      {props.icon ? <div className="text-input-icon">{props.icon}</div> : null}
      <input
        className="native-input"
        placeholder={props.placeholder}
        disabled={props.isDisabled}
        type={props.type}
        value={props.value}
        onChange={handleChange}
        onFocus={props.onFocus}
      ></input>
    </div>
  );
}
