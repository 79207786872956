
export class UpdateNavbarLocationsEvent {
    static fireUpdateNavbarLocations() {
        let event = new CustomEvent<void>("update-navbar-locations");
        window.dispatchEvent(event);

    }

    static attachHandler(handler: EventListener) {
        window.addEventListener("update-navbar-locations", handler);
    }

    static detachHandler(handler: EventListener) {
        window.removeEventListener("update-navbar-locations", handler);
    }
}

